import { defineStore } from 'pinia'
import { store } from '@/store'
import { UserAcType, UserLoginType, UserType } from '@/api/login/types'
import { ElMessageBox } from 'element-plus'
import { useI18n } from '@/hooks/web/useI18n'
import { loginOutApi } from '@/api/login'
import { useTagsViewStore } from './tagsView'
import { Menu } from '@/api/authorization/menu/types'
import { Organization } from '@/api/organization/organization/type'
import { useStorage } from '@/hooks/web/useStorage'

interface UserState {
  userInfo?: UserType
  userAc?: UserAcType
  menu?: Menu[]
  tokenKey: string //token在header里的key
  token: string //token值
  signKey: string //签名在header里的key
  sign: string //签名值
  org?: Organization //默认组织
  roleRouters?: string[] | AppCustomRouteRecordRaw[]
  rememberMe: boolean
  loginInfo?: UserLoginType
}

const { setStorage } = useStorage('localStorage')
export const useUserStore = defineStore('user', {
  state: (): UserState => {
    return {
      userInfo: undefined,
      userAc: undefined,
      menu: undefined,
      tokenKey: 'kbn-token',
      token: '',
      signKey: 'kbn-sign',
      sign: '',
      org: undefined,
      roleRouters: undefined,
      // 记住我
      rememberMe: true,
      loginInfo: undefined
    }
  },
  getters: {
    getTokenKey(): string {
      return this.tokenKey
    },
    getToken(): string {
      return this.token
    },
    getSignKey(): string {
      return this.signKey
    },
    getSign(): string {
      return this.sign
    },
    getOrg(): Organization | undefined {
      return this.org
    },
    getUserInfo(): UserType | undefined {
      return this.userInfo
    },
    getUserAc(): UserAcType | undefined {
      return this.userAc
    },
    getMenu(): Menu[] | undefined {
      return this.menu
    },
    getRoleRouters(): string[] | AppCustomRouteRecordRaw[] | undefined {
      return this.roleRouters
    },
    getRememberMe(): boolean {
      return this.rememberMe
    },
    getLoginInfo(): UserLoginType | undefined {
      return this.loginInfo
    }
  },
  actions: {
    setTokenKey(tokenKey: string) {
      this.tokenKey = tokenKey
    },
    setToken(token: string) {
      this.token = token
    },
    setSignKey(signKey: string) {
      this.signKey = signKey
    },
    setSign(sign: string) {
      this.sign = sign
    },
    setOrg(org?: Organization) {
      this.org = org
      setStorage('org', org)
    },
    setUserInfo(userInfo?: UserType) {
      this.userInfo = userInfo
    },
    setUserAc(userAc?: UserAcType) {
      this.userAc = userAc
    },
    setMenu(menu?: Menu[]) {
      this.menu = menu
    },
    setRoleRouters(roleRouters: string[] | AppCustomRouteRecordRaw[]) {
      this.roleRouters = roleRouters
    },
    logoutConfirm() {
      const { t } = useI18n()
      ElMessageBox.confirm(t('common.loginOutMessage'), t('common.reminder'), {
        confirmButtonText: t('common.ok'),
        cancelButtonText: t('common.cancel'),
        type: 'warning'
      })
        .then(async () => {
          const res = await loginOutApi().catch(() => {})
          if (res) {
            this.reset()
          }
        })
        .catch(() => {})
    },
    reset() {
      const tagsViewStore = useTagsViewStore()
      tagsViewStore.delAllViews()
      this.setToken('')
      this.setSign('')
      this.setUserInfo(undefined)
      this.setUserAc(undefined)
      //this.setOrg(undefined) //不删除默认组织，下次登录默认选择这个
      this.setRoleRouters([])
      //router.replace({ path: '/login', query: {} })

      const url = new URL(window.location.href)
      url.searchParams.delete('code')
      url.searchParams.delete('state')
      window.location.href = url.toString()
      return
    },
    logout() {
      this.reset()
    },
    setRememberMe(rememberMe: boolean) {
      this.rememberMe = rememberMe
    },
    setLoginInfo(loginInfo: UserLoginType | undefined) {
      this.loginInfo = loginInfo
    },
    menu2AppCustomRouteRecordRaw(menu: Menu[]): any[] {
      const raw: any[] = []
      for (let i = 0; i < menu.length; i++) {
        const children = menu[i].children ? this.menu2AppCustomRouteRecordRaw(menu[i].children) : []
        const permission: string[] = menu[i].permissionList.map((o) => {
          return o.value
        })
        const meta = {
          hidden: menu[i].hidden == 1,
          alwaysShow: menu[i].alwaysShow == 1,
          title: menu[i].name,
          icon: menu[i].icon,
          noCache: menu[i].noCache == 1,
          breadcrumb: menu[i].breadcrumb == 1,
          affix: menu[i].affix == 1,
          activeMenu: menu[i].activeMenu,
          noTagsView: menu[i].noTagsView == 1,
          canTo: menu[i].canTo == 1,
          permission: permission
        }
        const r = {
          name: menu[i].componentName,
          component: menu[i].component,
          path: menu[i].path,
          redirect: children.length ? menu[i].path + '/' + children[0].path : undefined,
          meta: meta,
          children: children
        }
        raw.push(r)
      }
      return raw
    } /*,
    async refreshMenu() {
      if (isEmpty(this.userInfo)) {
        return false
      }
      const res = await getUserMenuApi(this.userInfo?.id || 0)
      if (res.success) {
        this.setMenu(res.data)
        const routers = this.menu2AppCustomRouteRecordRaw(this.getMenu || [])
        this.setRoleRouters(routers)
        const appStore = useAppStore()
        const permissionStore = usePermissionStore()
        appStore.getDynamicRouter && appStore.getServerDynamicRouter
          ? await permissionStore.generateRoutes('server', routers).catch(() => {})
          : await permissionStore.generateRoutes('frontEnd', routers).catch(() => {})
      }
    }*/
  },
  persist: true
})

export const useUserStoreWithOut = () => {
  return useUserStore(store)
}
